import axios from "axios";

export const fetchImage = async (imgUrl) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_MOCK_PDF_API}/proxy-image?image=${imgUrl}`, {
      responseType: "blob",
    });
    const blob = res.data;
    const dataUrl = URL.createObjectURL(blob);
    return dataUrl;
  } catch (error) {
    console.log("Image fetch error for", imgUrl, "", error);
    return null;
  }
};
